a{
	color:inherit;
	&:hover, 
	&:focus{
		outline: none;
		text-decoration: none;
	}
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

.page-header{
	position: relative;

	border-bottom: none;

	&:before{
		content: "";
		width: 100%;
		// height: 2px;
		border-bottom: 2px dashed #FFD101;

		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);

		z-index: -1;
	}
	
	h1{
		position: relative;
		display: inline-block;

		font-family: "baloo_bhairegular";
		font-size: 23px;
		color: #FFF;

		padding: 18px 15px 13px;
		background: #FF2943;

		&:before,
		&:after{
			content: "";

			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			border-top: 28px solid transparent;
			border-right: 16px solid transparent;
			border-bottom: 28px solid transparent;
			border-left: 16px solid transparent;
		}

		&:before{
			border-right-color: #FF2943;
			border-bottom-color: #FF2943;
			right: 100%;
		}

		&:after{
			border-left-color: #FF2943;
			border-top-color: #FF2943;
			left: 100%;
		}

		.icon{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 100%;
			z-index: 100;
		}
	}

	h3{
		font-size: 17px;
		color: #333;
		font-weight: bold;

		.fa{
			position: relative;
			color: $cor-default;
			font-size: 30px;
			margin-right: 15px;
			top: 2.5px;
		}
	}

	.pagers{
		position: absolute;
		top: 50%;
		right: 0;
		padding-left: 50px;

		transform: translateY(-50%);

		background: #FFF;

		.pager{
			position: relative;
			display: inline-block;
			border-radius: 50%;

			color: #FFF;

			width: 40px;
			height: 40px;
			font-size: 18px;

			margin: 0 2.5px;
			background: #CA7DAA;

			&:hover{
				background: #9F547F;
			}

			&:first-child{
				margin-left: 0;
			}

			&:last-child{
				margin-right: 0;
			}

			.fa{
				position: absolute;
				top: 50%;
				left: 50%;

				font-size: 25px;

				transform: translateX(-50%) translateY(-50%);
			}
		}
	}
}

.header-internas{
	h1{
		font-size: 27px;
		color: #000;
	}

	h2{
		font-size: 17px;
		color: #656565;
		font-weight: bold;
	}

	a{
		display: inline-block;
		color: $cor-default;
		font-size: 13px;

		margin-top: 25px;
		margin-left: 15px;
	}
}

@keyframes infiniteRipple{
	
	0%{
		transform:scale(0);
		opacity:1;
	}

	100%{
		transform:scale(10);
		opacity:0;
	}

}

#btn-whats{
	z-index: 500;
	position: fixed;
	bottom: 15px;
	left: 15px;
	background-color: #4caf50;
	font-size: 40px;
	color: #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	box-shadow: 0 0 16px rgba(0,0,0,.5);


	&:after{
		content: '';
		position: absolute;
		z-index: -1;
		top: calc(50% - 5px);
		left: calc(50% - 5px);
		width: 10px;
		height: 10px;
		background-color: rgba(76,175,80,1);
		border-radius: 50%;
		opacity: 0;
		animation: infiniteRipple 1.5s infinite;
	}

}