.rodape{
	font-size: 13px;
	color: #656565;
	font-weight: bold;
	font-style: italic;
	background: $bg-footer;

	h3{
		font-family: "baloo_bhairegular";
		font-size: 20px;
		color: #353533;

		margin-top: 0;
		margin-bottom: 25px;
	}

	bold{
		display: block;
	}

	.fz-12{
		font-size: 12px;
	}

	ul{
		li{
			margin-bottom: 10px;
			a{
				&:hover{
					color: $cor-default;
				}
			}
		}
	}

	.redes-sociais{
		a{
			display: inline-block;
			vertical-align: middle;

			color: #7C7C7C;

			margin: 0 15px;

			&:first-child{
				margin-left: 0;
			}

			&:last-child{
				margin-right: 0;
			}

			&:hover{
				color: $cor-default;
			}
			
			.fa{
				font-size: 20px;
			}
		}
	}

	.creditos{
		margin-top: 35px;
		padding: 25px 0;
		font-size: 13px;
		color: #353535;
		font-weight: normal;
		font-style: normal;

		p{
			margin-bottom: 0;
		}

		a{
			font-weight: bold;
		}
	}

	.duvida{
		margin-top: 40px;
		position: relative;
		font-style: italic;

		font-size: 15px;
		color: #999;

		padding-left: 70px;

		.icon{
			position: absolute;
			top: 0;
			left: 0;
		}

		.photo{
			font-family: "baloo_bhairegular";
			font-size: 50px;
			color: #353533;
			font-style: normal;
		}
	}
}

.politica__privacidade{
	width: calc(100% - 120px);
	max-width: 1140px;
	background: #FFF;
	padding: 15px;
	border-radius: 10px;
	box-shadow: 0 0 8px rgba(#000,.8);
	position: fixed;
	left: 50%;
	bottom: 15px;
	transform: translateX(-50%);
	z-index: 1000;

	a{
		display: inline-block;
		color: #7B459D;
		font-weight: 900;
		text-decoration: underline;
	}

	@media(min-width: 768px) {
		display: grid;
		grid-template-columns: 1fr min-content;
		grid-gap: 15px;
		align-items: center;
	}

	@media(max-width: 767px) {
		text-align: center;

		.btn{
			margin-top: 15px;
		}
	}

	.btn{
		padding: .75rem 2rem;
		min-height: auto;
		@include button-variant(#FFF, #7B459D, #7B459D);
	}
}