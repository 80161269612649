.aside-conta{
	margin-top: 50px;
	box-shadow: 1px 1px 9px rgba(#0D1A23, .19);

	.heading{
		padding: 18px 23px;
		h2{
			position: relative;
			padding-left: 30px;
			margin: 0;
			font-size: 14px;
			color: #FFF;

			small{
				display: block;
				font-size: 11px;
				color: #000;
			}

			.fa,
			.icon{
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}

		a{
			position: relative;
			z-index: 19;
			margin-top: -18px;
			float: right;
			font-size: 11px;
			color: #000;
			&:hover{
				color: #FFF;
				text-decoration: none;
			}
		}

		.fa{
			font-size: 15px;
			color: #FFF;
		}

		background: #7b469e;
		// background: $cor-default;
	}

	.aside-body{
		ul{
			li{
				&:not(:last-child){
					border-bottom: 1px solid #E1E1E1;
				}

				a{
					display: block;
					padding-top: 25px;
					padding-bottom: 20px;
					padding-left: 23px;
					padding-right: 23px;

					font-size: 13px;
					color: #333;
				}

				&:hover, 
				&.active{
					a{
						color: $cor-default;
						text-decoration: none;
					}
				}
			}
		}
	}
}

.form-dados-user{
	padding-left: 183px;

	label{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 100%;
	}
}


.form-group{
    margin-bottom: 20px;
}

label{
    font-family: "Trebuchet MS";
    font-size: 14px;
    color: #656565;
    margin-bottom: 10px;
}

.form-control{
    min-height: 42px;
    border-radius: 0;
    box-shadow: none;

    font-family: "Trebuchet MS";
    font-size: 13px;
    color: #878787;
    border-color: #CCC;

    @include placeholdIt(){
        font-family: "Trebuchet MS";
        font-size: 14px;
        color: #878787;
    }

    &:focus{
        outline: none;
    }
}

textarea{
    resize: none;
}

input[type="radio"],
input[type="checkbox"]{
    display: none;

    + label{
        position: relative;
        padding-left: 25px;
        margin-right: 15px;
        cursor: pointer;

        font-family: "Trebuchet MS";
        font-size: 13px;
        color: #888;

        &:before{
            content: "";

            display: inline-block;
            width: 15px;
            height: 15px;
            border: 1px solid #999;
            border-radius: 50%;

            position: absolute;
            top: calc(50% - 7.5px);
            left: 0;
        }

        &:after{
            content: "";

            display: inline-block;
            width: 9px;
            height: 9px;
            background: #999;
            border-radius: 50%;

            position: absolute;
            top: calc(50% - 4.5px);
            left: 3px;

            opacity: 0;
        }
    }

    &:checked{
        + label{
            &:after{
                opacity: 1;
            }
        }
    }
}

.group-radio{
    min-height: 42px;
    padding-top: 10px;
}

.input-disabled{
    background: #e1e1e1;
}