.btn{
	font-size: 15px;
	min-height: 50px;
	font-weight: bold;

	padding-top: 13px;
	padding-bottom: 13px;

	padding-left: 35px;
	padding-right: 30px;
	border-radius: 4px;
}

.btn-chamada{
	font-family: "Museo 900";
	font-size: 16px;
	border-radius: 6px;
	padding: 10px 0;
	text-align: center;

	.fa{
		font-size: 20px;
		margin-right: 2.5px;
	}
}

.btn-black{
	color: #FFF;
	background: #ffa200;
	// border-bottom-color: darken(#C48000, 15%);;

	&:hover{
		color: #FFF;
	}
}
.btn-yellow{
	color: #FFF;
	background: #C48000;
	// border-bottom-color: darken(#C48000, 15%);;

	&:hover{
		color: #FFF;
	}
}

.btn-red{
	color: #FFF;
	background: #EE1B0A;
	// border-bottom-color: darken(#EE1B0A, 15%);

	&:hover{
		color: #FFF;
	}
}


.btn-red-dark{
	color: #FFF;
	background: #7b469e;
	// border-bottom-color: darken(#EE1B0A, 15%);

	&:hover{
		color: #FFF;
	}
}

.btn-gray{
	color: #333;
	background: #CACACA;
	// border-bottom-color: darken(#CACACA, 15%);

	&:hover{
		color: #333;
	}
}

.btn-orange{
	color: #FFF;
	background: #954973;
	// border-bottom-color: darken(#F58533, 15%);

	&:hover{
		color: #FFF;
	}
}


.btn-orange-light{
	color: #FFF;
	background: #ffab1b;
	// border-bottom-color: darken(#F58533, 15%);

	&:hover{
		color: #FFF;
	}
}

.btn-green{
	color: #FFF;
	background: #4bae53;
	// border-bottom-color: darken(#F58533, 15%);

	&:hover{
		color: #FFF;
	}
}


.btn-gray-dark{
	color: #FFF;
	background: #5b5b5b;
	// border-bottom-color: darken(#CACACA, 15%);

	&:hover{
		color: #FFF;
	}
}

.btn-purple{
	color: #FFF;
	background: #7b469e;
	// border-bottom-color: darken(#F58533, 15%);

	&:hover{
		color: #FFF;
	}
}

.btn-call-green{
	background: #3BAB35;
}

.btn-call-blue{
	background: #4b89c7;
}

.btn-call-green,
.btn-call-blue{
	display: inline-block;
	min-width: 162px;
	border-radius: 45px;
	
	padding-left: 27px;
	padding-right: 23px;
	padding-top: 8px;
	padding-bottom: 8px;

	min-height: 45px;

	.icon, 
	.fa,
	span{
		display: inline-block;
		vertical-align: middle;
	}

	.fa{
		font-size: 25px;
		color: #FFF;
		margin-left: 10px;
	}

	.icon,
	.fa{
		margin-right: 10px;
	}

	span{
		font-family: "baloo_bhairegular";
		font-size: 15px;
		color: #FFF;


		line-height: 110%;
	}
}