.informativos{
	padding: 15px 0;
	background: #ffd201;
	
	.info-item{
		position: relative;
		padding-left: 60px;
		margin-bottom: 10px;

		@media (max-width: $screen-xs-max){
			margin-bottom: 30px;
		}

		h3{
			font-family: "baloo_bhairegular";
			font-size: 18px;
			color: #fc4b5e;

			margin-bottom: 0;
		}

		span{
			font-family: "robotoregular";
			font-size: 12px;
			color: #333;
			font-weight: bold;
		}

		.icon{
			position: absolute;
			top: 50%;
			left: 0;

			transform: translateY(-50%);
		}
	}
}

.banners{
	position: relative;

	.owl-dots{
		position: absolute;
		z-index: 19;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);

		.owl-dot{
			display: inline-block;
			width: 56px;
			height: 3px;
			margin: 0 2px;
			cursor: pointer;
			background: #FFF;

			&:first-child{
				margin-left: 0;
			}
			&:last-child{
				margin-right: 0;
			}

			&.active{
				background: #FF2943;
			}
		}
	}

	.pager{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 119;
		display: none;

		&.pager-prev{
			left: calc(50% - 585px);
		}

		&.pager-next{
			right: calc(50% - 585px);
		}
	}
}

.newsletter{
	margin-top: 60px;
	margin-bottom: 10px;
	padding-top: 35px;
	padding-bottom: 25px;
	background: $bg-newsletter;

	header{
		position: relative;
		width: 100%;
		@media (min-width: $screen-sm-min){
			max-width: 50%;
		}


		.icon{
			position: absolute;
			top: 50%;
			left: 0;

			transform: translateY(-50%);
		}

		h1{
			margin: 0;
			font-size: 20px;
			color: #FFF;
			font-family: "baloo_bhairegular";
			font-size: 30px;
			color: #FFF;
		}

		p{
			font-family: "baloo_bhairegular";
			font-size: 13px;
			color: #E8AEFF;
		}
	}

	.form-newsletter{
		position: relative;
		width: 100%;
		@media (min-width: $screen-sm-min){
			max-width: 40%;
		}
		
		&:before{
			content: "";

			position: absolute;
			top: 50%;
			right: calc(100% - 10px);

			display: inline-block;
			width: 131px;
			height: 207px;

			transform: translateY(-50%);

			background: $before-newsletter;

			@media (max-width: $screen-xs-max){
				display: none;
			}
		}
		
		form{
			.input-newsletter{
				border-radius: 45px;
				background: #FFF;
				overflow: hidden;
				display: flex;
				justify-content: space-between;
				align-items: center;

				flex-wrap: wrap;

				input,
				button{
					display: inline-block;
					vertical-align: middle;
					&:focus{
						outline: none;
					}
				}
			}

			input{
				border: none;
				width: 100%;
				height: 46px;
				@media (max-width: $screen-xs-max){
					margin-bottom: 30px;
				}
				@media (min-width: $screen-sm-min){
					max-width: calc(100% - 155px);
				}
				padding-left: 35px;

				font-family: "baloo_bhairegular";
				font-size: 15px;
				color: #333;

				@include placeholdIt(){
					color: #999;
				}

				background: #FFF;
			}

			.btn-newsletter{
				position: absolute;
				top: -2.5px;
				right: 0;

				// transform: translateY(-50%);

				font-family: "baloo_bhairegular";
				font-size: 15px;
				color: #FFF;

				border: 2px solid #FFF;

				width: 50px;
				height: 50px;

				text-align: center;

				border-radius: 50%;

				display: flex;
				justify-content: center;
				align-items: center;
				
				background: #ff2a43;
			}
		}
	}
}

.banners-content-full,
.kits-content-full{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	
	.banners-default,
	.kit-group{
		width: 100%;
		@media(min-width: $screen-sm-min){
			max-width: 50%;
		}
		overflow: hidden;
	}
}

.kits-content-full{
	.kit-group{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		
		.kit-default{
			width: 100%;
			height: 100%;

			@media(min-width: $screen-sm-min){
				max-width: 50%;
			}

			.photo{
				position: relative;
				padding-bottom: 100%;
				background: #ececec;

				.picture{
					position: absolute;
					width: 100%;
					height: 100%;

					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: wrap;
				}
			}

			&.kit-content{
				.item-default{
					position: relative;
					padding-bottom: 100%;

					.item-content{
						position: absolute;
						width: 100%;
						height: 100%;

						padding-top: 40px;
						padding-left: 40px;

						@media(min-width: $screen-sm-min){
							padding-top: 130px;
							padding-left: 100px;
						}

						.nome{
							font-size: 30px;
							color: #333;
							margin-bottom: 20px;
						}

						.qnt{
							font-family: "robotobold";
							font-size: 50px;
							color: #333;
							@media(min-width: $screen-sm-min){
								margin-bottom: 50px;
							}

							strong{
								color: $cor-default;
							}
						}

						.itens{
							padding-left: 0;
							list-style: none;

							font-family: "roboto_italic";
							font-size: 17px;
							color: #333;

							li{
								&:before{
									content: "";

									display: inline-block;
									vertical-align: middle;
									margin-right: 15px;
									width: 6px;
									height: 6px;
									border-radius: 50%;
									background: #333;
								}
							}
						}
					}

					&:before,
					&:after{
						content: "";

						border-top: 25px solid transparent;
						border-right: 25px solid transparent;
						border-bottom: 25px solid transparent;
						border-left: 25px solid transparent;

						position: absolute;
					}
					&:before{
						left: calc(50% - 12.5px);
						bottom: 100%;
						border-bottom-color: #FFF;
					}
					
					&:after{
						top: 50%;	
						right: 100%;
						border-right-color: #FFF;
					}
				}
			}
		}
	}
}

.banners-content-full{
	.banners-default{
		height: 412px;
		overflow: hidden;

		.item-default{
			position: relative;
			width: 100%;

			@media(min-width: $screen-sm-min){
				max-width: 585px;
			}
			height: 100%;
			padding-top: 120px;


			@media(max-width: $screen-xs-max){
				padding: 120px 40px 0;
			}

			h1{
				margin: 0;
				font-weight: bold;
				font-size: 65px;
				font-style: italic;
				font-weight: bold;

				line-height: 90%;
				text-shadow: 0 0 10px rgba(#000, .5);

				strong{
					display: block;
				}
			}
			
			h2{
				@media(min-width: $screen-sm-min){
					padding-left: 80px;
				}
				margin: 0;
				font-size: 35px;
				font-style: italic;
				font-weight: bold;
				text-shadow: 0 0 10px rgba(#000, .5);
			}

			a{
				position: absolute;
				display: block;

				&.link-top{
					top: 45px;
				}
				&.link-bottom{
					bottom: 35px;
				}
				font-size: 18px;
				font-style: italic;
				color: #FFF;

				text-decoration: none;
			}
		}

		&.banner-acessorios,
		&.banner-originais{
			.item-default{
				float: right;
			}
		}

		&.banner-exclusivas,
		&.banner-modernas{
			.item-default{
				@media(min-width: $screen-sm-min){
					padding-left: 100px;
				}
			}
		}

		&.banner-acessorios{
			background: $bg-banner-acessorios;
			background-size: cover;
		}
		&.banner-exclusivas{
			background: $bg-banner-exclusivas;
			background-size: cover;
		}
		&.banner-originais{
			background: $bg-banner-originais;
			background-size: cover;
		}
		&.banner-modernas{
			background: $bg-banner-modernas;
			background-size: cover;
		}

	}
}

.novidades{
	padding-top: 115px;
	padding-bottom: 135px;
	// background: $bg-novidades;

	h3{
		font-family: "robotobold_italic";
		font-size: 80px;
		color: #FFF;
		margin-bottom: 55px;
		letter-spacing: -5px;
	}

	.form-novidades{
		span{
			font-family: "roboto_light";
			font-size: 20px;
			color: #FFF;
			font-style: italic;
		}
		
		button{
			width: 100%;
			min-height: 54px;
			border-radius: 45px;

			font-family: "robotobold";
			font-size: 14px;
			color: #FFF;

			background: $cor-default;
			letter-spacing: 5px;
		}
		input{
			margin-top: 50px;
			width: 100%;
			min-height: 54px;
			border-radius: 45px;
			border: none;
			background: #FFF;

			font-family: "roboto_light";
			font-size: 14px;
			color: #999;
			font-style: italic;

			padding-left: 32px;

			&:focus{
				outline: none;
			}

			@include placeholdIt(){
				font-family: "roboto_light";
				font-size: 14px;
				color: #999;
				font-style: italic;
			}
		}

		a{
			display: block;
			margin: 20px 0 25px;
			font-family: "roboto_light";
			font-size: 14px;
			font-style: italic;
			color: $cor-default;
		}
	}
}

.ofertas-relampago{
	background: #F3F3F3;

	.oferta-default{
		.item-default{
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			flex-wrap: wrap;
			
			.produto-item,
			.tempo-item{
				width: 100%;
				@media (min-width: $screen-sm-min){
					max-width: 50%;
				}
				text-align: center;
			}
			.produto-item{
				padding-top: 40px;
				background: #FFF;
				.nome{
					font-family: "robotobold_italic";
					font-size: 30px;
					color: #333;
				}

				.photo{
					position: relative;
					padding-bottom: 100%;
					.picture{
						position: absolute;
						width: 100%;
						height: 100%;

						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
			
			.tempo-item{
				padding-top: 60px;
				background: #F58533;

				.tempo{
					font-family: "robotobold_italic";
					font-size: 50px;
					color: #FFF;
				}
			}
		}
	}
}

.content-carousel{
	position: relative;

	.pager{
		position: absolute;
		top: 165px;
		transform: translateY(-50%);
		z-index: 119;
		margin: 0;

		font-size: 50px;
		color: #949494;

		&.pager-prev{
			right: calc(100% + 20px);
		}

		&.pager-next{
			left: calc(100% + 20px);
		}
	}
}

.content-carousel-chamadas{
	position: relative;
	margin: 50px 0;

	.pager{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		margin: 0;

		background: $cor-default;

		width: 80px;
		padding: 4px 0;

		border-radius: 40px;

		@media (max-width: $screen-xs-max){
			display: none;
		}

		&:hover{
			background: #9D6BAD;
		}

		&.pager-prev{
			padding-left: 15px;
			text-align: left;
			right: calc(100% - 50px);
		}

		&.pager-next{
			padding-right: 15px;
			text-align: right;
			left: calc(100% - 50px);
		}

		.fa{
			font-size: 35px;
			color: #FFF;
		}
	}

	.chamada-item{
		position: relative;
		z-index: 199;

		.photo{
			position: relative;
			padding-bottom: 100%;

			.picture{
				position: absolute;
				width: 100%;
				height: 100%;

				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}

.cronometro{
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;

	margin: 40px 0;

	.block-1{
		position: relative;
		width: 100%;
		@media (min-width: $screen-sm-min){
			max-width: 63%;
		}
		background: #FF9100;

		&:before{
			content: "";

			display: inline-block;
			width: 359px;
			height: 380px;
			
			position: absolute;
			left: 100%;
			bottom: 0;

			z-index: 199;

			transform: translateX(-50%);

			background: $bg-menina;

			@media (max-width: $screen-xs-max){
				display: none;
			}
		}
	}

	.block-2{
		width: 100%;
		@media (min-width: $screen-sm-min){
			max-width: 37%;
		}

		.cronometro-time,
		.cronometro-bottom{
			height: 50%;


			@media (max-width: $screen-xs-max){
				min-height: 250px;
			}
		}

		.cronometro-time{
			background: #A71BDF;
		}

		.cronometro-bottom{
			text-align: center;
			position: relative;
			background: $bg-yellow;

			figure{
				position: absolute;
				bottom: 35px;
				right: 20px;

				z-index: 19;
			}
		}
	}

	.cronometro-time{
		text-align: center;
		padding-top: 20px;
		
		h3{
			display: inline-block;
			background: #FFF;

			font-family: "baloo_bhairegular";
			font-size: 22px;
			color: #000;
			padding: 15px 15px 10px;
		}

		p{
			font-family: "baloo_bhairegular";
			font-size: 18px;
			color: #FFD101;
		}
	}

	.cronometro-grid{
		margin-bottom: 15px;

		.grid{
			position: relative;
			display: inline-block;
			vertical-align: middle;
			font-family: "baloo_bhairegular";
			color: #FFF;
			text-align: center;

			&:not(:last-child){
				margin-right: 25px;

				&:after{
					content: ":";

					position: absolute;
					top: 10px;
					left: calc(100% + 10px);

					display: inline-block;
					font-size: 30px;
				}
			}
			
			.nro{
				font-size: 45px;
			}
			
			span{
				font-size: 13px;
			}
		}
	}
}

.produtos-destaques{
	.col-md-3{
		&:not(:nth-child(4), :nth-child(8)){
			.produto-default{
				&:before{
					content: "";

					position: absolute;
					left: calc(100% + 15px);
					top: 0;

					width: 4px;
					height: 100%;

					background: #f3f3f4;
				}
			}
		}
	}
}
body{
	max-width: 100%;
	overflow: hidden;
	clear: both;
	
	&:after{
		display:block;
		content: '';
		clear: both;
	}
}
@media (max-width: 768px) {
	.informativos{
		padding: 5px 0px;

		.info-item{
			margin-bottom: 5px;
			display: flex;
			align-items: center;
			padding-left: 10px;

			.icon{
				position: relative;
				top: auto;
				left: auto;
				transform: none;
				margin-right: 5px;
			}
			h3{
				margin-top: 0px;
			}
		}
		.col-lg-3{
			&:last-child{
				.info-item{
					margin-bottom: 0px;
				}
			}
		}
	}
}
.marcas-header{
	text-align: center;
	max-width: 100% !important;
	margin-bottom: 15px;
}
.segura__marcas__carousel{
	position: relative;
	padding: 0px 10px;

	.pager{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		margin: 0;

		background: #fff;
		color: #9D6BAD;

		width: 30px;

		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover{
			background: #9D6BAD;
			
			.fa{
				color: #fff;
			}
		}

		&.pager-prev{
			text-align: left;
			right: calc(100% - 15px);
			padding-bottom: 2px;
			padding-right: 3px;
		}

		&.pager-next{
			text-align: right;
			left: calc(100% - 15px);
			padding-bottom: 2px;
			padding-left: 3px;
		}

		.fa{
			font-size: 30px;
			line-height: 30px;
			color: #9D6BAD;
		}
	}
}
.marca-item{
	text-align: center;
	display: block;
	max-width: 200px;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
}
#modal__vindo.modal{
	.modal-dialog{
		max-width: 100%;
		width: 650px;

		.modal-content{
			background-image: url('../imagens/estrutural/bg__modal.jpg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			border-radius: 0px;
		}
	}
	.segura-modal-botao{
		display: flex;
		justify-content: flex-end;

		.fechar__botao{
			display: flex;
			align-items:center;
			justify-content: center;
			width: 50px;
			height: 50px;
			font-size: 20px;
			line-height: 20px;
			background-color: #FF2943;
			color: #fff;
			border: 2px solid #fff;
			border-radius: 50%;

			&:focus{
				outline: none;
			}
		}
	}
	.titulo-modal{
		font-family: "baloo_bhairegular";
		font-size: 44px;
		line-height: 48px;
		text-align: center;
		color: #fff;
		margin-bottom: 0px;	
	}
	.sub-modal{
		margin-top: 0px;
		font-family: 'latoregular';
		font-size: 18px;
		line-height: 22px;
		color: #fff;
		text-align: center;
		letter-spacing: 1.4px;
	}
	.modal-header{
		padding: 0px;
		border-bottom: 0px;
		margin-bottom: 30px;
	}
	.modal__input{
		display: block;
		width: 100%;
		height: auto;
		padding: 16px 20px 14px 20px;
		border: none;
		border-radius: 30px;

		&:focus{
			outline: none;
		}
	}
	.form-group{
		margin-bottom: 12px;
	}
	.modal-form{
		max-width: 480px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		font-family: "baloo_bhairegular";
	}
	.segura__modal__botao{
		display: flex;
		justify-content: center;
		padding: 20px 0px 60px 0px;
	}
	.modal__botao{
		width: 200px;
		display: flex;
		align-items:center;
		justify-content: center;
		height: 54px;
		font-size: 16px;
		line-height: 16px;
		background-color: #FF2943;
		color: #fff;
		border: 2px solid #fff;
		border-radius: 54px;
		font-family: "baloo_bhairegular";
	}
}