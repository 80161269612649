@font-face {
	font-family: 'Trebuchet MS';
	src: url('#{$fonts}/trebuchet_bold/fonts/TrebuchetMS-Bold.eot');
	src: url('#{$fonts}/trebuchet_bold/fonts/TrebuchetMS-Bold.eot?#iefix') format('embedded-opentype'),
		url('#{$fonts}/trebuchet_bold/fonts/TrebuchetMS-Bold.woff') format('woff'),
		url('#{$fonts}/trebuchet_bold/fonts/TrebuchetMS-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
    font-family: 'roboto_light';
    src: url('#{$fonts}/roboto_light/fonts/Roboto-Light.eot');
    src: url('#{$fonts}/roboto_light/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts}/roboto_light/fonts/Roboto-Light.woff') format('woff'),
        url('#{$fonts}/roboto_light/fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'robotobold_italic';
    src: url('#{$fonts}/roboto_bolditalic/fonts/roboto-bolditalic-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_bolditalic/fonts/roboto-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotobold';
    src: url('#{$fonts}/roboto_bold/fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_bold/fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latoregular';
    src: url('#{$fonts}/lato_regular/fonts/lato-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_regular/fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoregular';
    src: url('#{$fonts}/roboto_regular/fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_regular/fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansextrabold';
    src: url('#{$fonts}/opensans_extrabold/fonts/opensans-extrabold-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans_extrabold/fonts/opensans-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansregular';
    src: url('#{$fonts}/opensans_regular/opensans-regular-webfont.eot');
    src: url('#{$fonts}/opensans_regular/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.woff') format('woff'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Museo 900';
    src: url('#{$fonts}/museo_900/fonts/Museo-900.eot');
    src: url('#{$fonts}/museo_900/fonts/Museo-900.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts}/museo_900/fonts/Museo-900.woff') format('woff'),
        url('#{$fonts}/museo_900/fonts/Museo-900.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'roboto_italic';
    src: url('#{$fonts}/roboto_italic/fonts/Roboto-Italic.eot');
    src: url('#{$fonts}/roboto_italic/fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts}/roboto_italic/fonts/Roboto-Italic.woff') format('woff'),
        url('#{$fonts}/roboto_italic/fonts/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'montserratbold';
    src: url('#{$fonts}/montserrat_bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_bold/montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto_condenseditalic';
    src: url('#{$fonts}/robotocondensed_italic/fonts/robotocondensed-italic-webfont.woff2') format('woff2'),
         url('#{$fonts}/robotocondensed_italic/fonts/robotocondensed-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto_condensedlight';
    src: url('#{$fonts}/robotocondensed_light/fonts/robotocondensed-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/robotocondensed_light/fonts/robotocondensed-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'amaranth_bolditalic';
    src: url('#{$fonts}/amaranth_bolditalic/amaranth-bolditalic-webfont.woff2') format('woff2'),
         url('#{$fonts}/amaranth_bolditalic/amaranth-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'lato_black';
    src: url('#{$fonts}/lato_black/fonts/Lato-Black.eot');
    src: url('#{$fonts}/lato_black/fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts}/lato_black/fonts/Lato-Black.woff') format('woff'),
        url('#{$fonts}/lato_black/fonts/Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'baloo_bhairegular';
    src: url('#{$fonts}/baloobhai_regular/fonts/baloobhai-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/baloobhai_regular/fonts/baloobhai-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}