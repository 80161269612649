.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}


.noround{
    border-radius:0;
}

.img-responsive{
    display: inline-block;
}

.container-flex{
    display: flex;
    flex-wrap: wrap;
}

.jc-between{
    justify-content: space-between;
}

.align-center{
    align-items: center;
}

.align-top{
    align-items: flex-start;
}

.align-bottom{
    align-items: flex-end;
}

.unlistenner{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.owl-nav{
    display: none;
}

.c-white{
    color: #FFF;
}

.c-orange{
    color: $cor-default;
}

.c-yellow{
    color: rgba(#FFD101, 1);
}

.bg-white{
    background: #FFF;
}

.button-left{
    position: relative;

    .dropdown-menu{
        border-radius: 0;
        margin: 0;
        box-shadow: none;
        border: none;

        min-width: 220px;
        background: $cor-default;
        
        li{
            a{
                display: block;
                font-family: "roboto_light";
                font-size: 14px;

                color: #FFF;
                padding: 12px 15px;

                &:hover{
                    background: lighten($cor-default, 10%);
                }
            }
        }
    }
}

.btn-todas-categorias{
    margin: 0;
    display: block;

    padding-top: 25px;
    padding-bottom: 22px;
    padding-left: 10px;
    padding-right: 20px;

    font-family: "roboto_light";
    font-size: 14px;
    color: $cor-default;

    &:hover{
        color: #FFF;
        background: $cor-default;
    }

    i{
        margin-right: 10px;
    }
}

.border-top{
    border-top: 1px solid #D2D2D2;
}

.tooltip-inner{
    border-radius: 45px;
    font-family: "latoregular";
    font-size: 10px;
    color: #FFF;
}

.c-orange{
    color: #FF9100;
}