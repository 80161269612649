$br: -59px 0px 48px 33px;
$en: 0px -43px 48px 33px;
$es: 0px 0px 49px 33px;

@mixin sprite-width($sprite) {
	width: nth($sprite, 3);
}
@mixin sprite-height($sprite) {
	height: nth($sprite, 4);
}
@function sprite-width($sprite) {
	@return nth($sprite, 3);
}
@function sprite-height($sprite) {
	@return nth($sprite, 4);
}
@mixin sprite-position($sprite) {
	$sprite-offset-x: nth($sprite, 1);
	$sprite-offset-y: nth($sprite, 2);
	background-position: $sprite-offset-x $sprite-offset-y;
}
@mixin sprite($sprite, $display: block) {
	@include sprite-position($sprite);
	background-repeat: no-repeat;
	overflow: hidden;
	display: $display;
	@include sprite-width($sprite);
	@include sprite-height($sprite);
}
.icon {
	background-image: url(../images/sprite.png);
}
