.topo{
	position: relative;
	z-index: 19;
	background: $bg-topo;

	&:before{
		content: "";

		display: block;
		width: 100%;
		height: 10px;

		background: $after-topo;
	}

	.busca{
		margin-bottom: 30px;

		p{
			font-family: "baloo_bhairegular";
			font-size: 14px;
			color: #666;

			a{
				color: #e50915;
			}
		}

		.form-busca{
			.input-busca{
				width: 100%;
				@media (min-width: $screen-md-min){
					width: 300px;
				}
				border-radius: 45px;
				border: 1px solid #DBDBDD;
				background: #FFF;

				input,
				button{
					display: inline-block;
					vertical-align: middle;
					min-height: 45px;
					border: none;
					background: transparent;

					&:focus{
						outline: none;
					}
				}

				input{
					width: calc(100% - 70px);
					font-family: "baloo_bhairegular";
					font-size: 15px;
					color: #333;

					padding-left: 20px;

					@include placeholdIt(){
						color: #999;
					}
				}
				
				button{
					width: 65px;
				}
			}
		}
	}

	.navbar-toggle{
		float: none;
		border: 1px solid $cor-default;
		color: $cor-default;
	}

	.navbar-nav{
		display: block;
		float: none;
		background: $bg-menu;

		@media (max-width: $screen-xs-max){
			width: 100%;
			text-align: right;

			#menu{
				text-align: left;
			}
		}

		#menu{
			@media (min-width: $screen-sm-min){
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
			}

			@media (max-width: $screen-xs-max){
				justify-content: space-around;	
			}

			li{
				position: relative;

				&:nth-child(1){ a{color: #e50915}}
				&:nth-child(2){ a{color: #ffa200}}
				&:nth-child(3){ a{color: #38a838}}
				&:nth-child(4){ a{color: #ff32e9}}
				&:nth-child(5){ a{color: #7b469e}}
				&:nth-child(6){ a{color: #666666}}
				&:nth-child(7){ a{color: #1E5EB4}}
				
				a{
					display: block;
					font-family: "baloo_bhairegular";
					font-size: 16px;
					color: #FFF;

					padding: 25px 15px 22px;
				}

				&.active,
				&:hover{
					a{
						color: #FFF;
						text-decoration: none;
					}

					&:nth-child(1){ a{background: #e50915}}
					&:nth-child(2){ a{background: #ffa200}}
					&:nth-child(3){ a{background: #38a838}}
					&:nth-child(4){ a{background: #ff32e9}}
					&:nth-child(5){ a{background: #7b469e}}
					&:nth-child(6){ a{background: #666666}}
					&:nth-child(7){ a{background: #1E5EB4}}
				}

				&:hover{
					.submenu{
						max-height: initial;
					}
				}

				.submenu{
					z-index: 19;

					@media (min-width: $screen-sm-min){
						position: absolute;
						left: 0;
						top: 100%;

						max-height: 0;
						overflow: hidden;
					}
					background: #FFF;

					ul{
						min-width: 210px;

						li{
							a{
								padding: 10px 15px;
								
								@media (max-width: $screen-xs-max){
									padding-left: 40px;
								}

								&:hover{
									background: rgba(#313131, 1);
								}
							}
						}
					}
				}
			}
		}
	}

	

	.acoes-vertical{
		width: 180px;
		
		a{
			position: relative;
			display: inline-block;
			vertical-align: middle;

			margin: 0 3px;

			width: 49px;
			height: 47px;
			border-radius: 50%;

			background: #DBDBDD;

			&.c-red{
				background: #E50915;
			}

			&.c-yellow{
				background: #FFA100;
			}

			&.c-blue{
				background: #4B89C7;
			}

			&:first-child{
				margin-left: 0;
			}

			&:last-child{
				margin-right: 0;
			}

			.fa,
			.icon{
				position: absolute;
				top: 50%;
				left: 50%;

				transform: translateX(-50%) translateY(-50%);
			}

			span{
				position: absolute;
				top: 5px;
				right: 5px;

				width: 17px;
				height: 17px;
				border-radius: 50%;

				display: flex;
				justify-content: center;
				align-items: center;

				font-family: "baloo_bhairegular";
				font-size: 12px;
				color: #FFF;

				background: #FF4952;
			}
		}
	}

	.logo{
		margin-bottom: 10px;

		@media (max-width: $screen-xs-max){
			width: 100%;
			text-align: center;
			margin: 40px 0;
		}
	}
}