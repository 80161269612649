.paginacao{
	margin-top:20px;
	margin-bottom:30px;
	padding-top: 25px;
	border-top: 2px solid #EBEBEB;

	> *{
		display: inline-block;
		padding:8px 14px;

		color: #979797;
		background: #C9C9C9;
		border-radius: 6px;

		margin: 0 2.5px;
		font-weight: bold;
	}

	.active,
	*:hover{
		text-decoration:none;
		background: #F3C800;
		color: #FFF;
	}
}
