.pedido-detalhes{
	box-shadow: 1px 1px 9px rgba(#0D1A23, .19);
	padding-bottom: 20px;

	.panel-pedido{
		&.status-entrega{
			.entrega,
			.status{
				&:not(.entrega){
					background: rgba(#3FAA0E, 1);
				}

				&.entrega{
					background: darken(#3FAA0E, 5%);
				}
			}

		}
		
		&.status-transporte{
			.entrega,
			.status{
				&:not(.entrega){
					background: rgba(#007AE3, 1);
				}

				&.entrega{
					background: darken(#007AE3, 5%);
				}
			}

		}
		
		&.status-pagamento{
			.entrega,
			.status{
				&:not(.entrega){
					background: rgba(#A00C00, 1);
				}

				&.entrega{
					background: darken(#A00C00, 5%);
				}
			}

		}

		.dados{
			display: flex;
			align-items: stretch;
			justify-content: space-between;

			.entrega,
			.status{
				padding-top: 17px;
				padding-bottom: 13px;
				
				font-size: 12px;
				color: #FFF;
			}

			.entrega{
				min-width: 45%;
				position: relative;
				padding-left: 15px;
				padding-right: 15px;

				span{
					display: block;
					margin-bottom: 10px;
				}
				
				.dia{
					display: inline-block;
					font-size: 40px;
					font-weight: bold;
					line-height: 120%;
				}

				.mes-ano{
					display: inline-block;
					font-size: 17px;
				}
			}

			.status{
				min-width: 55%;
				padding-left: 23px;
			
				.status-prod{
					margin-top: 8px;
					margin-bottom: 12px;
					font-size: 18px;
					font-weight: bold;
				}
			}
		}
	}

	.produto-detalhes{
		padding-left: 35px;
		padding-right: 35px;

		.content-center{
			padding-top: 25px;
			padding-bottom: 25px;
			border-bottom: 1px solid #CCC;

			.photo{
				position: relative;
				min-width: 91px;
				padding-bottom: 95px;
				border: 1px solid #CCC;

				.picture{
					position: absolute;
					width: 100%;
					height: 100%;

					display: flex;
					justify-content: center;
					align-items: center;
				}
			}


			.photo,
			.dados{
				display: inline-block;
				vertical-align: middle;
			}

			.dados{
				padding-left: 20px;
				
				font-size: 12px;
				color: #656565;
				font-weight: bold;

				.nome{
					margin-bottom: 12px;
				}
			}
		}
	}

	.acompanhar-pedido{
		margin-top: 60px;
		ul{
			display: flex;
			justify-content: space-between;
			align-items: center;

			unicode-bidi: bidi-override;
			direction: rtl;

			li{
				position: relative;
				padding-top: 30px;
				width: calc(100% / 3);

				.icon{
					position: absolute;
					left: 50%;
					bottom: calc(100% + 6px);
					z-index: 69;

					transform: translateX(-50%);

					opacity: 0;
				}

				&.active{
					&{
						&:before{
							background: $cor-default;
						}
					}

					& ~ li{
						&:before,
						&:after{
							background: $cor-default;
						}
					}
				}

				&:before,
				&:after{
					content: '';
					display: inline-block;
					position: absolute;


					background: rgba(#DDD, 1);
				}
				
				&:before{
					width: 24px;
					height: 24px;

					border-radius: 50%;

					left: 50%;
					transform: translateX(-50%);
					bottom: 100%;
					z-index: 29;
				}

				&:after{
					width: 100%;
					height: 3px;
					left: 50%;
					bottom: calc(100% + 12px);
				}

				&:first-child{
					&:before{
						width: 44px;
						height: 44px;
						bottom: calc(100% - 6px);
					}

					&:after{
						display: none;
					}

					&.active{
						.icon{
							opacity: 1;
						}
					}
				}

				span{
					display: block;
					
					font-size: 12px;
					color: #878787;
					font-weight: bold;
					text-align: center;
					@include resumo(12px, 1.2, 2);
				}
			}
		}
	}

	.btn-pedido{
		display: inline-block;
		border-radius: 8px;
		width: 100%;
		@media (min-width: $screen-sm-min){
			max-width: 313px;
		}
		padding-top: 14px;
		padding-bottom: 12px;

		
		font-size: 14px;
		color: #FFF;

		background: $cor-default;
	}
}

.pagamento-detalhes{
	border: 1px solid #2272e0;

	.item-default{
		h3{
			margin-top: 23px;
			margin-left: 23px;
			
			font-size: 15px;
			color: #333;
			font-weight: bold;
		}

		.tipo-pagamento{
			margin-top: 15px;
			margin-bottom: 20px;

			padding-left: 25px;
			padding-right: 25px;
			
			font-size: 13px;
			font-weight: bold;
			color: #656565;

			.photo,
			span{
				display: inline-block;
				vertical-align: middle;
			}

			span{
				padding-left: 16px;
			}
		}

		.valores{
			border-top: 1px solid #CBCBCB;

			.valor-item{
				
				font-size: 13px;
				color: #484848;
				padding: 5px 23px;

				&:nth-child(even){
					background: #EDECEC;
				}

				.nome,
				.valor{
					display: inline-block;
					vertical-align: middle;

					&.nome{
						min-width: 70%;
					}
				}

				&.valor-total{
					padding-top: 23px;
					padding-bottom: 15px;

					.nome{
						font-weight: bold;
						color: #171717;
						min-width: 60%;
					}

					.valor{
						font-size: 20px;
						color: $cor-default;
					}
				}
			}
		}

		.dados-entrega{
			margin-top: 15px;
			padding-left: 23px;
			padding-right: 23px;
			
			.entrega-item{
				padding-top: 10px;
				padding-bottom: 10px;
				
				font-size: 13px;
				color: #656565;

				position: relative;
				padding-left: 30px;

				.icon{
					position: absolute;
					top: 50%;
					left: 0;

					transform: translateY(-50%);
				}

				font-weight: bold;

				&:last-child{
					border-top: 1px solid #CCC;
				}

				span{
					display: block;
					font-size: 11px;
					color: #333;
				}
			}
		}
	}
}

.pedido-default{
	margin-top: 15px;
	margin-bottom: 35px;

	.item-default{
		.pedido-data{
			position: relative;
			text-align: right;

			&:before{
				content: "";

				display: inline-block;
				width: 100%;
				height: 1px;
				z-index: -1;

				position: absolute;
				top: calc(50% - .5px);
				left: 0;
				background: #D6D6D6;
			}

			.ped-data{
				position: relative;
				z-index: 19;
				padding-left: 12px;
				background: #FFF;
				display: inline-block;
				
				font-size: 16px;
				color: #656565;
				font-weight: bold;
			}

			margin-bottom: 15px;

		}

		.panel-pedido{
			box-shadow: 1px 1px 9px rgba(#0D1A23, .19);
			
			display: flex;
			align-self: baseline;
			align-items: center;
			flex-wrap: wrap;

			
			&.status-entrega{
				.entrega,
				.status,
				.info{
					&:not(.info){
						@media (min-width: $screen-sm-min){
							width: 30%;
						}
						background: rgba(#3FAA0E, 1);
					}

					&.info{
						@media (min-width: $screen-sm-min){
							width: 40%;
						}
						background: darken(#3FAA0E, 5%);
					}
				}

			}
			
			&.status-transporte{
				.entrega,
				.status,
				.info{
					&:not(.info){
						@media (min-width: $screen-sm-min){
							width: 30%;
						}
						background: rgba(#007AE3, 1);
					}

					&.info{
						@media (min-width: $screen-sm-min){
							width: 40%;
						}
						background: darken(#007AE3, 5%);
					}
				}

			}
			
			&.status-pagamento{
				.entrega,
				.status,
				.info{
					&:not(.info){
						@media (min-width: $screen-sm-min){
							width: 30%;
						}
						background: rgba(#A00C00, 1);
					}

					&.info{
						@media (min-width: $screen-sm-min){
							width: 40%;
						}
						background: darken(#A00C00, 5%);
					}
				}

			}

			.dados,
			.detalhes{
				display: flex;
				flex-wrap: wrap;
			}

			.dados{
				@media (min-width: $screen-sm-min){
					width: calc(100% - 340px);
				}
				align-items: stretch;
				justify-content: space-between;

				.entrega,
				.status,
				.info{
					padding-top: 30px;
					padding-bottom: 25px;
					
					font-size: 12px;
					color: #FFF;


					@media (max-width: $screen-xs-max){
						width: 100%;
					}
				}

				.entrega{
					position: relative;
					text-align: center;
					padding-left: 15px;
					padding-right: 15px;
					
					@media (min-width: $screen-sm-min){
						&:after{
							content: "";
							
							display: inline-block;

							position: absolute;
							width: 4px;
							height: calc(100% - 65px);

							top: 50%;
							left: calc(100% - 2px);
							transform: translateY(-50%);

							background: #FFF;
						}
					}
					.dia{
						font-size: 60px;
						font-weight: bold;
						line-height: 120%;
					}

					.mes-ano{
						font-size: 17px;
					}
				}

				.status{
					padding-left: 23px;
					
					.status-prod{
						margin-top: 15px;
						font-size: 18px;
						font-weight: bold;

					}
				}

				.info{
					padding-left: 17px;

					.nro,
					.valor{
						margin-top: 7px;
						font-size: 15px;
					}
				}
			}

			.detalhes{
				@media (min-width: $screen-sm-min){
					width: 340px;
					padding-left: 33px;
				}
				@media (max-width: $screen-xs-max){
					padding: 40px 10px;
					.photo{
						margin-right: 15px;
					}

				}
				align-items: center;
				justify-content: space-around;

				.photo{
					position: relative;
					min-width: 91px;
					padding-bottom: 95px;
					border: 1px solid #CCC;

					.picture{
						position: absolute;
						width: 100%;
						height: 100%;

						display: flex;
						justify-content: center;
						align-items: center;
					}
				}

				.data{
					
					font-size: 11px;
					color: #656565;
					font-weight: bold;
					text-align: center;

					.btn-detalhes{
						border-radius: 4px;
						display: block;
						
						font-size: 14px;
						color: #FFF;
						padding-top: 10px;
						padding-bottom: 10px;

						background: $cor-default;

						margin-top: 15px;
						
						&:hover{
							text-decoration: none;
							background: darken($cor-default, 10%);
						}
					}
				}
			}
		}
	}
}

.pedido-tabs{
	border: 1px solid #CCC;

	ul{
		display: flex;
		justify-content: space-around;
		align-items: center;

		li{
			a{
				display: block;
				
				font-size: 14px;
				color: #666;
				font-weight: bold;

				padding: 15px 8px;
				border-top: 2px solid transparent;
			}

			&:hover,
			&.active{
				a{
					border-top-color: $cor-default;
					color: $cor-default;
					text-decoration: none;
				}
			}

		}
	}
}

.filtro-periodo{
	margin-top: 30px;
	margin-bottom: 30px;

	.input-select,
	label{
		display: inline-block;
		vertical-align: middle;
	}

	label{
		margin-top: 15px;
		margin-right: 5px;
	}

	.input-select{
		max-width: 150px;
	}
}

.input-select{
	border: 1px solid #CCC;
	height: 42px;
	width: 100%;
	
	position: relative;
	background: #FFF;

	> select{
		position: absolute;
		width: 100%;
		height: 100%;

		border: none;
		background: transparent;
		
		font-weight: bold;
	}
}