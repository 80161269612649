.produto-default{
	position: relative;
	margin-bottom: 45px;
	
	&:hover{
		.item-default{
			.photo{
				.picture{
					opacity: .5;
				}
			}
		}
	}
	.item-default{
		.photo{
			position: relative;
			padding-bottom: 330px;
			overflow: hidden;

			.novo,
			.desconto{
				position: absolute;
				top: 10px;
				right: 10px;
				
				width: 64px;
				height: 65px;

				z-index: 29;
				
				border-radius: 50%;

				display: flex;
				justify-content: center;
				align-items: center;

				font-family: "baloo_bhairegular";
				font-size: 17px;
				color: #FFF;

				background: #FF2943;
			}

			.picture{
				position: absolute;
				width: 100%;
				height: 100%;

				display: flex;
				justify-content: center;
				align-items: center;

				transition: opacity .4s;
			}
		}
		.dados{
			text-align: center;

			.nome{
				font-family: "baloo_bhairegular";
				color: #515151;

				margin-bottom: 20px;
				@include resumo(15px, 1.2, 2);
				font-weight: bold;
			}

			.desc{
				font-family: "robotoregular";
				color: #999;

				margin-bottom: 20px;
				@include resumo(13px, 1.2, 3);
			}

			.valores{
				font-family: "latoregular";
				font-size: 14px;
				color: rgba(#252525, 1);

				.preco-min{
					font-family: "latoregular";
					font-size: 13px;
					color: #999;

					span{
						font-family: "baloo_bhairegular";
						color: #999;
						font-size: 14px;
   						text-decoration: line-through;
					}
				}

				.preco-max{
					font-family: "baloo_bhairegular";
					font-size: 30px;
					color: rgba(#353B91, 1);
					font-style: normal;
				}

				span{
					font-family: "baloo_bhairegular";
					font-size: 13px;
					color: #00AEFF;
				}
			}
			.botoes-vertical{
				display: flex;
				justify-content: space-between;
				align-items: center;

				&.mgt-30{
					margin-top: 20px !important;
				}

				.btn-compra{
					min-width: 162px;
					min-width: 162px;
					border-radius: 45px;
					
					padding-left: 22px;
					padding-right: 18px;
					padding-top: 10px;
					padding-bottom: 10px;

					min-height: 41px;

					font-family: "baloo_bhairegular";
					font-size: 13px;
					color: #FFF;

					background: #FFA100;

					&:hover{
						color: #FFF;
					}

					.icon, 
					.fa,
					span{
						display: inline-block;
						vertical-align: middle;
					}

					.fa{
						font-size: 25px;
						color: #FFF;
						margin-left: 10px;
					}

					.icon{
						margin-right: 10px;
					}
				}

				> a{
					position: relative;
					display: inline-block;
					vertical-align: middle;

					min-width: 42px;
					height: 41px;
					border-radius: 50%;

					background: #DBDBDD;

					&.c-purple{
						background: #7B459D;
					}

					&.c-blue{
						background: #4B89C7;
					}

					.fa,
					.icon{
						position: absolute;
						top: 50%;
						left: 50%;

						transform: translateX(-50%) translateY(-50%);
					}
				}
			}
		}
	}
}

.produto-oferta{
	position: relative;
	margin-bottom: 30px;

	.item-default{
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
		
		.item-left,
		.dados{
			width: 100%;

			@media (min-width: $screen-sm-min){
				max-width: 50%
			}
		}

		.item-left{
			.photo{
				position: relative;
				padding-bottom: (186/254)*100%;

				.desconto{
					position: absolute;
					top: 0;
					left: 0;

					z-index: 19;

					width: 82px;
					height: 79px;

					font-family: "montserratbold";
					font-size: 25px;
					color: #FFF;
					
					border-radius: 50%;

					display: flex;
					justify-content: center;
					align-items: center;

					transform: translateY(-50%);
					background: #CC2019;
				}

				.picture{
					position: absolute;
					width: 100%;
					height: 100%;

					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.btn-compra{
				margin-top: 20px;
				background: #FF4903;
				width: 100%;
				border: none;


				font-family: "montserratbold";
				color: #FFB900;
				font-size: 24px;

				min-height: 55px;
				border-radius: 6px;

				&:hover{
					background: #FF4903;
				}
			}
		}

		.dados{
			@media (min-width: $screen-sm-min){
				padding-left: 30px;
			}

			.desc{
				font-family: "robotoregular";
				font-size: 18px;
				color: #C91E23;
			}

			.nome{
				font-family: "robotobold_italic";
				color: #FFF;

				margin-bottom: 30px;
				@include resumo(30px, 1.2, 2);
			}
			.preco-min,
			.preco-max{
				font-family: "montserratbold";
				line-height:  110%;
			}

			.preco-min{
				font-size: 20px;
				color: #999;

				span{
					text-decoration: line-through;
				}
			}

			.preco-max{
				position: relative;
				padding-left: 55px;
				font-size: 80px;
				color: #C91E23;
				margin-bottom: 12px;

				span{
					position: absolute;
					font-size: 35px;
					left: 0;
					top: 0;
				}
			}
		}
	}
}

.detalhes-produtos{
	.nome-produto,
	.cod,
	.desc{
		
		color: #000;
	}
	
	.nome-produto{
		font-size: 18px;
		font-weight: bold;
	}

	.cod{
		font-size: 12px;
		font-style: italic;
	}

	.desc{
		font-size: 12px;
		color: #333;
	}

	.cat-avaliacao{
		font-family: "montserratregular";
		font-size: 11px;
		color: #656565;
		margin-bottom: 15px;
		padding-top: 10px;
		border-top: 5px solid #EDEDED;

		.avaliacao,
		span{
			display: inline-block;
			vertical-align: middle;
		}

		.avaliacao{
			margin-right: 10px;

			unicode-bidi: bidi-override;
			direction: rtl;

			a{
				display: inline-block;
				margin: 1px;

				&.active{
					&,
					& ~ a{
						i{
							color: #FFD700;
						}
					}
				}

				&:first-child{
					margin-left: 0;
				}
				
				&:last-child{
					margin-right: 0;
				}
				
				.fa{
					font-size: 16px;
					color: #999;
				}
			}
		}

		span{
			font-size: 10px;
			color: #4b89c8;
			font-weight: bold;
		}
	}
	
	.carrinho,
	.valores,
	.calcular-cep{
		padding-top: 15px;
		padding-bottom: 10px;
		margin-bottom: 10px;
		background: #ececec;
		padding-left: 20px;
		padding-right: 20px;
		
		
	}

	.valores{
		.precos{
			position: relative;
			font-size: 12px;
			color: #2F2F2F;

			&:after{
				content: "";
				height: 100%;
				width: 2px;
				background: #d2d0d0;

				position: absolute;
				top: 50%;
				left: calc(100% - 10px);

				transform: translateY(-50%)
			}
			
			.preco-menor{
				// font-style: italic;
				font-family: "baloo_bhairegular";
				font-size: 14px;
				color: #353b91;

				strong{
					font-weight: bold;
					font-size: 22px;
				}
			}
			
			.preco-maior{
				font-family: "baloo_bhairegular";
				font-size: 11px;
				color: #00aeff;
			}
		}

		.frete{
			font-size: 10px;
			color: $cor-default;
			padding-left: 20px;

			// h4{
			// 	font-style: italic;
			// 	margin-bottom: 5px;
			// }

			color: #333;
			font-size: 10px;

			strong{
				display: block;
				font-size: 11px;
				text-transform: uppercase;
			}
		}

		.precos,
		.frete{
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			max-width: 49%;
		}
	}

	.carrinho{
		margin-top: 10px;
		font-size: 10px;
		color: #333;
		
		a{
			display: block;
		}
	}

	.calcular-cep{
		> a{
			display: block;
			margin-top: 10px;
			font-size: 10px;
			color: #333;

			i{
				transform: rotateY(180deg);
				font-size: 16px;
				margin-right: 5px;
			}
		}
		.input-cep{
			display: inline-block;
			width: 100%;
			@media (min-width: $screen-sm-min){
				max-width: 260px;
			}
			border: 1px solid #d7d7d7;
			background: #FFF;

			input,
			button{
				display: inline-block;
				background: transparent;
				border: none;

				&:focus{
					outline: none;
				}
			}

			input{
				width: calc(100% - 80px);
				height: 43px;

				
				font-size: 12px;
				color: #8f8f8a;
				padding-left: 18px;

				@include placeholdIt(){
					
					font-size: 12px;
					color: #8f8f8a;

				}
			}

			button{
				width: 60px;
			}
		}
	}

	.form-horizontal{
		form{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		a{
			position: relative;
			display: inline-block;
			width: 25px;
			height: 25px;

			border-radius: 50%;

			background: #ffab1b;
			color: #FFF;
			padding-top: 2.5px;
			padding-left: 10px;
		}
	}

	.btn-adicionar-carrinho{
		font-family: "Museo 900";
		font-size: 18px;
		color: #FFF;
		background: $cor-default;
		padding-top: 9px;
		padding-bottom: 9px;
		border-radius: 6px;
		text-align: center;
	}
}

.miniatures{
	.miniatura{
		padding-top: 20px;
		padding-bottom: 20px;
		text-align: center;
	
		.picture{
			position: relative;
			display: inline-block;
			width: 50px;
			height: 50px;
			background: #FFF;
			box-shadow: 0 0 10px rgba(#0e1b23, .3);

			img{
				position: absolute;
				width: 100%;
				height: 100%;
				left: 50%;
				top: 50%;

				transform: translateX(-50%) translateY(-50%);
			}
		}
	}
}

.titulo-mini{
	
	color: #000;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
}

.button-black{
	
	color: #FFF;
	font-size: 12px;

	background: #000;
	display: block;
	min-width: 130px;
	padding-top: 8px;
	padding-bottom: 8px;
	border-radius: 6px;
	text-align: center;
	&:hover{
		color: #FFF;
	}
}

.links-prod{
	
	font-size: 12px;
	
	.info{
		font-weight: bold;
		color: #000;
	}
	
	.voltar{
		color: #A5A5A5;
		font-style: italic;
	}
	a{
		&:not(:last-child){
			&:after{
				content: "|";
				
				
				color: #000;

				display: inline-block;
				margin: 0 10px;
			}
		}
	}
}

.shared{
	margin-top: 20px;
	
	font-size: 12px;
	font-weight: bold;
	font-style: italic;
	color: #817F7B
}

.produto-destaque{
	position: relative;
	padding-bottom: 422px;

	.figure{
		position: absolute;
		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;

	}
}

.produtos-tabs{
	border-bottom: none;
	ul{
		li{
			display: inline-block;
			a{
				display: block;
				
				font-size: 13px;
				color: #040303;
				min-width: 119px;
				text-align: center;
				background: #DCDCDC;
				padding-top: 9px;
				padding-bottom: 3px;
			}

			&:hover,
			&.active{
				a{
					background: #ECECEC;
				}
			}
		}
	}
}

.tab-produtos{
	padding: 20px 30px;
	background: #ECECEC;
	
	
	color: #040303;

	h2{
		margin-top: 0;
		margin-bottom: 23px;
		font-size: 20px;
		font-weight: bold;
	}
}

.footer-produtos-detalhes{
	margin-top: 80px;
	padding-top: 40px;
	border-top: 4px solid #ECECEC;
}
.icon{
	background-image: url('../imagens/estrutural/sprite.png') !important;
}
@media (max-width: 768px) {
	.page-header{
		&.flex__column{
			display:flex;
			flex-direction: column;
			align-items: center;
	
			.pagers{
				padding-left: 0px;
				position: relative;
				transform: none;
				left: 0px;
				top: 0px;
			}
		}
	}
}