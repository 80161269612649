.videos-vertical{
	position: relative;
	border: 1px solid #D3D1D1;
	
	.heading{
		padding: 25px 25px 23px;
		background: #A00C00;

		h3{
			font-family: "latoblack";
			font-size: 14px;
			color: #FFF;

			margin: 0;
		}
	}

	.content-scroll{
		padding: 20px 10px 10px 25px;

		.lista-videos{
			overflow-x: hidden;
			max-height: 330px;

			.video-default{

				&:not(:last-child){
					.item-default{
						border-bottom: 1px solid #E1E1E1;
					}
				}

				.item-default{
					display: flex;
					justify-content: space-between;
					padding-bottom: 20px;
					margin-bottom: 10px;

					.photo{
						width: 135px;
					}

					.dados{
						padding-left: 20px;
						.nome{
							font-family: "robotobold";
							font-size: 14px;
							color: #333;

							@include resumo(14px, 1.2, 2);
						}
						.desc{
							font-family: "robotoregular";
							font-size: 11px;
							color: #878787;
						}
					}
				}
			}
		}
	}
}