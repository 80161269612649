$imgs: '../imagens/estrutural';
$fonts: '../fonts';

$cor-default: rgba(#7b469e, 1);

$bg-topo: rgba(#edeef1, 1);
$after-topo: url(#{$imgs}/after-topo.png) center top repeat-x;

$bg-menu: rgba(#FFF, 1);

$bg-banner-acessorios: url(#{$imgs}/banner-acessorios.png) center top no-repeat;
$bg-banner-exclusivas: url(#{$imgs}/banner-bikes.png) center top no-repeat;
$bg-banner-originais: url(#{$imgs}/banner-pecas.png) center top no-repeat;
$bg-banner-modernas: url(#{$imgs}/banner-roupas.png) center top no-repeat;

$bg-footer: rgba(#f8f8f8, 1);
$bg-creditos: rgba(#FFF, 1);

$bg-newsletter: url(#{$imgs}/bg-novidades.png) center top repeat-y;
$before-newsletter: url(#{$imgs}/before-newsletter.png) center bottom no-repeat;
$bg-sprite: url(#{$imgs}/sprite.png) no-repeat;
$bg-menina: url(#{$imgs}/menina.png) center top no-repeat;

$bg-yellow: url(#{$imgs}/bg-yellow.png) right bottom no-repeat #F9DD2D;