.breadcrumbs{
	padding-top: 10px;
	padding-bottom: 10px;
	background: #ffa200;

	ul{
		li{
			display: inline-block;

			&:not(:last-child){
				a{
					&:after{
						content: "/";
						display: inline-block;
						margin: 0 5px;
					}
				}
			}

			&:last-child{
				a{
					color: #b91008;
				}
			}

			a{
				display: block;
				font-size: 11px;
				color: #FFF;

				&:hover{
					text-decoration: none;
				}
			}
		}
	}
}

.internas{
	padding-bottom: 20px;

	iframe{
		max-width: 100%;
	}

	.content-internas{
		padding: 25px 30px;
		margin-top: 50px;
		box-shadow: 1px 1px 9px rgba(#0D1A23, .19);

		&.no-shadow{
			box-shadow: none;
		}

		.subtitle,
		.subtitle-section{
			position: relative;
			padding-top: 12px;
			padding-bottom: 14px;
			
			margin-top: 20px;
			margin-bottom: 30px;
			
			&:before{
				content: "";
				display: inline-block;
				width: 2px;
				height: 100%;

				position: absolute;
				top: 0;
				left: -29px;
				background: $cor-default;
			}
			
			@media (min-width: $screen-md-min){

				&:after{
					content: "";
					display: inline-block;
					width: 100%;
					max-width: 788px;
					height: 1px;

					position: absolute;
					top: calc(50% - .5px);
					right: 0;
					background: #D6D6D6;
					z-index: -1;
				}
			}

		}

		.subtitle{
			font-size: 18px;
			font-weight: bold;
			color: $cor-default;

			&:first-letter{
				margin-left: 40px;
			}
		}


		.subtitle-section{

			h2{
				display: inline-block;
				background: #FFF;
				font-family: "latoregular";
				font-size: 14px;
				color: #333;
				margin: 0;
				padding-right: 15px;
			}
		}

		.text{
			p{
				font-size: 13px;
				color: #555;
				margin-bottom: 40px;

				&:first-letter{
					margin-left: 40px;
				}
			}
		}
	}
}

.campo-observacao{
	font-size: 12px;
	color: $cor-default;
	font-weight: bold;
}

.form-center{
	@media (min-width: $screen-sm-min){
		padding-left: 90px;
		padding-right: 97px;
	}
}

.login-content{
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	.login,
	.cadastro{
		width: 100%;

		@media (min-width: $screen-md-min){
			padding-left: 117px;
			padding-right: 127px;


			max-width: 50%;
		}

		h3{
			font-size: 15px;
			color: #000;
			margin-top: 0;
		}
		
		.form-group{
			margin-bottom: 15px;
		}
		
		label{
			margin-bottom: 12px;

			font-size: 13px;
			color: #000;
		}

		.form-control{
			border-radius: 4px;
		}

		p{
			font-size: 13px;
			color: #000;

			.circle-left{
				display: inline-block;
				width: 5px;
				height: 5px;
				background: #EE1B0A;
				border-radius: 50%;
				margin-right: 5px;
			}
		}
	}

	.login{
		position: relative;
		margin-bottom: 40px;

		@media (min-width: $screen-md-min){
			margin-bottom: 0;
			&:after{
				content: "";

				position: absolute;
				height: 100%;
				width: 1px;

				display: inline-block;
				top: 50%;
				left: calc(100% - .5px);

				transform: translateY(-50%);

				background: #C7C7C7;
			}
		}

		.form-login{
			a{
				font-size: 11px;
				color: $cor-default;
				display: block;
				text-align: right;
				margin-bottom: 10px;
			}
		}
	}
}


.form-section{
	h3,
	h4{
        color: #333;
        padding-left: 30px;
	}

	h3{
		font-weight: bold;
		font-size: 17px;
	}

	h4{
		font-size: 13px;
	}
}

.nome-categoria{
    font-family: "open_sansextrabold";
    color: #1F1F1F;
    font-size: 30px;
    font-style: italic;
    padding-left: 20px;
    margin-bottom: 20px;
}

.filtros-busca{
	margin-bottom: 20px;
    padding-left: 20px;
	.filtro{
		position: relative;
		display: inline-block;
		vertical-align: middle;

        color: #333;
        font-weight: bold;
    	font-style: italic;
    	padding-right: 30px;
	}
}

.aside-busca{
	.aside-content{
		padding-right: 25px;

		h3{
	        font-size: 13px;
	        color: #000;
	        font-weight: bold;
		}

		.exibicao{
			font-size: 13px;
			font-weight: bold;
			font-style: italic;
			color: #737171;
			margin-bottom: 20px;

			a{
				display: inline-block;
				margin: 0 2.5px;
				font-size: 16px;

				&:hover,
				&.active{
					color: #000;
				}

				&:first-child{
					margin-left: 10px;
				}
			}
		}

		.heading{
			padding: 10px 20px;
			background: #E50815;

			h2{
		        font-size: 13px;
		        color: #fff;
		        margin: 0;
			}
		}

		.block-aside{
			margin-top: 35px;
			padding: 0 20px;


	        font-size: 13px;
	        color: #000;

	        span{
	        	font-size: 11px;
	        	color: #7D8083;
	        }

	        .input-busca{
	        	position: relative;
	        	border-radius: 6px;
	        	background: #999;

	        	input,
	        	button{
	        		background: none;
	        		border: none;

	        		&:focus{
	        			outline: none;
	        		}
	        	}

	        	input{
	        		width: calc(100% - 53px);
	        		min-height: 40px;
	        		padding-left: 17px;

			        font-size: 13px;
			        color: #000;
			        font-weight: bold;
			        font-style: italic;

			        @include placeholdIt(){
				        font-size: 13px;
				        color: #000;
			        }
	        	}

	        	button{
	        		position: absolute;
	        		top: 0;
	        		right: 0;

	        		width: 43px;
	        		height: 100%;

	        		display: flex;
	        		justify-content: center;
	        		align-items: center;
	        	}
	        }

	        .input-preco{
	        	input{
	        		display: block;
	        		width: 120px;
				    min-height: 35px;
				    border-radius: 0;
				    box-shadow: none;
				    padding-left: 17px;

				    font-size: 14px;
				    color: #878787;
				    border: 1px solid #CCC;

				    @include placeholdIt(){
				        font-size: 14px;
				        color: #878787;
				    }

				    &:focus{
				        outline: none;
				    }
	        	}
	        }
		}

		.ic-circle{
			display: inline-block;

			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: 1px solid #000;
			margin-right: 8px;

			display: none;
		}

		ul{
			margin-top: 20px;

			li{
				position: relative;

				&.active{
					&:after{
						display: inline-block;
					}
				}
				
				&:before{
					content: "";
					display: inline-block;
					vertical-align: middle;

					width: 12px;
					height: 12px;
					border-radius: 50%;
					margin-top: -2.5px;
					border: 1px solid #000;
					margin-right: 8px;
				}

				&:after{
					content: "";

					position: absolute;
					top: 50%;
					left: 3px;
					transform: translateY(-70%);

					width: 6px;
					height: 6px;
					border-radius: 50%;
					background: #000;
					margin-right: 8px;

					display: none;
				}

				&:hover,
				&.active{
					a{
						color: #000;
					}
				}

				a{
					display: inline-block;
					padding: 4px 0;
					color: #999;

					span{
						float: right;
					}
				}
			}
		}
	}
}

.title-busca{
	h2{
		font-family: "open_sansextrabold";
		font-size: 20px;
		color: #777;

		strong{
			color: #1F1F1F;
		}
	}
}

.sugestoes-busca{
	font-family: "open_sansregular";
	font-size: 13px;
	color: #777;
	
	.sugestoes{
		margin-left: 5px;
		display: inline-block;

		a{
			display: inline-block;
			color: #1F1F1F;
			text-decoration: underline;

			&:not(:last-child){
				&:after{
					content: "|";
					display: inline-block;
					margin: 0 5px;
				}
			}
		}
	}
}