.ic-atendente-white{
	@include make-icon(14px, 15px, 0 -457px);
}

.ic-atendente-pink{
	@include make-icon(14px, 15px, 0 -427px);
}

.ic-whatsapp-red{
	@include make-icon(16px, 16px, 0 -516px);
}

.ic-whatsapp-white{
	@include make-icon(16px, 16px, 0 -548px);
}

.ic-menu-white{
	@include make-icon(13px, 10px, 0 -401px);
}

.ic-menu-black{
	@include make-icon(21px, 14px, 0 -371px);
}

.ic-menu-yellow{
	@include make-icon(13px, 10px, 0 -1305px);
}

.ic-email-red{
	@include make-icon(17px, 12px, 0 -488px);
}

// .ic-pencil-white{
// 	@include make-icon(13px, 13px, 0 -580px);
// }

.ic-pencil-white{
	@include make-icon(13px, 13px, 0 -665px);
}

.ic-heart-white{
	@include make-icon(13px, 11px, 0 -608px);
}

.ic-heart-yellow{
	@include make-icon(13px, 11px, 0 -694px);
}

.ic-compare-white{
	@include make-icon(14px, 14px, 0 -635px);
}

.ic-compare-yellow{
	@include make-icon(14px, 14px, 0 -721px);
}

.ic-lupa{
	@include make-icon(22px, 22px, 0 0);
}

.ic-carrinho-red{
	@include make-icon(28px, 24px, 0 -37px);
}

.ic-carrinho-white{
	@include make-icon(28px, 24px, 0 -741px);
}

.ic-carrinho-yellow{
	@include make-icon(28px, 24px, 0 -772px);
}

.ic-carrinho-pink{
	@include make-icon(28px, 24px, 0 -2099px);
}
.ic-frete-entrega{
	@include make-icon(58px, 42px, 0 -118px);
}

.ic-desconto-loja{
	@include make-icon(68px, 48px, 0 -178px);
}

.ic-compra-segura{
	@include make-icon(41px, 52px, 0 -239px);
}

.ic-loja-aberta{
	@include make-icon(80px, 48px, 0 -307px);
}

.ic-user-white{
	@include make-icon(18px, 19px, 0 -807px);
}

.ic-close{
	@include make-icon(11px, 12px, 0 -836px);
}

.ic-check{
	@include make-icon(15px, 13px, 0 -854px);
}

.ic-truck-white{
	@include make-icon(20px, 15px, 0 -874px);
}

.ic-truck-red{
	@include make-icon(24px, 16px, 0 -903px);
}

.ic-user-red{
	@include make-icon(18px, 16px, 0 -935px);
}

.ic-marker-red{
	@include make-icon(12px, 18px, 0 -970px);
}

.ic-carrinho-black{
	@include make-icon(28px, 24px, 0 -1002px);
}

.ic-card{
	@include make-icon(21px, 14px, 0 -1032px);
}

.ic-pagseguro-red{
	@include make-icon(113px, 24px, 0 -1086px);
}

.ic-pagseguro-gray{
	@include make-icon(113px, 24px, 0 -1052px);
}

.ic-boleto-gray{
	@include make-icon(46px, 28px, 0 -1120px);
}

.ic-boleto-red{
	@include make-icon(46px, 28px, 0 -1158px);
}

.ic-impressora{
	@include make-icon(23px, 23px, 0 -1196px);
}

.ic-calendario{
	@include make-icon(22px, 22px, 0 -1273px);
}

.ic-validar{
	@include make-icon(22px, 21px, 0 -1235px);
}

.ic-atendente{
	@include make-icon(28px, 30px, 0 -1335px);
}

.ic-user-orange{
	@include make-icon(24px, 21px, 0 -1374px);
}
.ic-pager-prev{
	@include make-icon(66px, 142px, 0 -1407px);
}
.ic-pager-next{
	@include make-icon(66px, 142px, 0 -1577px);
}
.ic-headphone{
	@include make-icon(18px, 19px, 0 -2075px);
}
.ic-basket{
	@include make-icon(19px, 19px, 0 -2130px);
}
.ic-phone-big{
	@include make-icon(54px, 52px, 0 -2189px);
}

// icones

.ic-search{
	@include make-icon(26px, 23px, 0 0);
}

.ic-user{
	@include make-icon(25px, 25px, 0 -77px);
}

.ic-shopping{
	@include make-icon(28px, 24px, 0 -37px);
}

.ic-heart{
	@include make-icon(25px, 23px, -45px 0);
}

.ic-chat{
	@include make-icon(20px, 21px, -44px -37px);
}

.ic-look{
	@include make-icon(23px, 16px, -45px -77px);
}

.ic-phone-yellow{
	@include make-icon(52px, 49px, 0 -2126px);
}

.ic-o-entrega{
	@include make-icon(50px, 52px, 0 -118px);
}

.ic-o-parcela{
	@include make-icon(50px, 52px, 0 -176px);
}

.ic-o-cartao{
	@include make-icon(50px, 52px, 0 -238px);
}

.ic-o-codigo{
	@include make-icon(50px, 52px, 0 -298px);
}

.ic-paper-yellow{
	@include make-icon(50px, 46px, 0 -2181px);
}

.ic-moeda{
	@include make-icon(40px, 40px, 0 -2229px);
}
