.forma-pagamento-tabs{
	border: 1px solid #D2D2D2;
	
	h2{
		
		font-size: 20px;
		color: #333;
		padding-left: 15px;
	}
}

.pagamento-tabs{
	border-top: 1px solid #D2D2D2;
	border-bottom: 1px solid #D2D2D2;
	background: #EEE;

	ul{
		display: flex;
		flex-wrap: wrap;

		li{
			width: 100%;
			@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
				max-width: 50%;
			}

			@media (min-width: $screen-md-min){
				max-width: 379px;
			}

			a{
				display: block;
				padding-top: 17px;
				padding-bottom: 15px;
				text-align: center;

				
				font-size: 14px;
				color: #656565;

				border-left: 1px solid transparent;
				border-right: 1px solid transparent;

				.icon-center{
					position: relative;
					padding-bottom: 28px;
					margin-bottom: 15px;

					.picture{
						position: absolute;
						width: 100%;
						height: 100%;

						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
			
			&:hover,
			&.active{
				&:not(:first-child){
					a{
						border-left-color: #D2D2D2;
					}
				}

				a{
					margin: 0;
					background: #FFF;

					border-right-color: #D2D2D2;
					text-decoration: none;

					.icon{
						&.ic-pagseguro-gray{
							background-position: 0 -1086px;
						}
						&.ic-boleto-gray{
							background-position: 0 -1158px;
						}
					}
				}
			}
		}
	}
}

.tab-pagamento{
	
	font-size: 13px;
	color: #656565;

	.pagamento-content{		
		padding: 10px;

		@media (min-width: $screen-md-min){
			padding-top: 60px;
			padding-bottom: 40px;
			padding-left: 200px;
			padding-right: 200px;
		}
	}

	.desc-boleto{
		ul{
			li{
				margin-bottom: 15px;

				span{
					position: relative;
					border-radius: 50%;
					display: inline-block;
					width: 42px;
					height: 42px;
					background: $cor-default;
					vertical-align: middle;
					margin-right: 10px;

					i{
						position: absolute;
						top: 50%;
						left: 50%;

						transform: translateX(-50%) translateY(-50%);
					}
				}

				strong{
					color: $cor-default;
				}
			}
		}
	}

	.importante{
		h4{
			display: block;
			
			font-size: 13px;
			color: #2A2A2A;
			font-weight: bold;
		}

		ul{
			margin-top: 15px;
		}

		li{
			&:before{
				content: "";

				width: 5px;
				height: 5px;
				background: #242424;

				border-radius: 50%;

				position: absolute;
				top: 5px;
				left: 0;
			}
			
			position: relative;
			padding-left: 15px;
			
			font-size: 12px;
			color: #656565;
			font-weight: bold;
			line-height: 120%;
			margin-bottom: 10px;
		}
	}

	.total{
		font-size: 18px;
		font-weight: bold;
		color: $cor-default;
	}

	.text{
		
		font-size: 11px;
		color: #2F2F2F;
		font-weight: bold;
	}
}

.table-pedidos{
	border: 1px solid #D2D2D2;
	
	thead{
		
		font-size: 14px;
		color: #FFF;
		background: #FFA100;
	}

	tbody{
		tr{
			td{
				&:first-child{
					.resumo-pedido-table{
						border-right: 1px solid #D5D5D5;
					}
				}

				.end-entrega,
				.resumo-pedido-table{
					margin-top: 10px;
					margin-bottom: 10px;
					padding: 15px 40px;
				}

				.end-entrega{
					
					font-size: 14px;
					color: #333;
					line-height: 130%;

					a{
						display: block;
						color: $cor-default;
					}
				}

				.resumo-pedido-table{
					.item-row{
						display: flex;
						justify-content: space-between;

						
						font-size: 14px;
						color: #333;
						margin-bottom: 8px;

						a{
							color: $cor-default;
						}

						&:last-child{
							margin-top: 25px;
							padding-top: 25px;
							font-weight: bold;
							border-top: 1px solid #C5C5C5;
							
							.item-column{
								&:first-child{
									color: $cor-default;
								}
							}
						}
					}
				}
			}
		}
	}
}

.confirmar-compra{

	.titulo{
		font-family: "open_sansextrabold";
		font-size: 20px;
		color: $cor-default;

		small{
			font-family: "open_sansregular";
			font-size: 15px;
		}
	}

	.text{
		font-family: "open_sansregular";
		font-size: 13px;
		color: #6C6C6C;

		strong{
			font-weight: bold;
			display: block;
			color: #333333;
		}
	}
	
	.nro-label{
		font-family: "open_sansextrabold";
		font-size: 13px;
		color: #333;
	}

	.nro-pedido{
		font-family: "open_sansextrabold";
		font-size: 30px;
		color: $cor-default;
		margin-top: 20px;
	}

	p{
		font-family: "open_sansregular";
		font-size: 13px;
		color: #6C6C6C;

		a{
			color: $cor-default;
		}
	}
}