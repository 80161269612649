.table-borded{
	position: relative;
	border-left: 2px solid #E9E9E9;
	border-right: 2px solid #E9E9E9;
	margin-bottom: 80px;

	&:after{
		content: "";

		display: block;
		height: 9px;
		width: 100%;

		position: absolute;
		top: calc(100% + 45px);
		background: #F2F2F2;
	}
}

.table-carrinho{
	margin-bottom: 0;

	thead{
		background: #ffa200;
		tr{
			th{
				border: none;
				
				font-size: 13px;
				color: #FFF;
				font-weight: bold;

				&:not(:first-child){
					text-align: center;
				}
			}
		}
	}

	tbody{
		tr{
			td{
				&:first-child{
					border-left: none;
				}
				&:last-child{
					border-right: none;
				}
				border-left: 2px solid #E9E9E9;
				border-right: 2px solid #E9E9E9;
				border-bottom: 2px solid #2F2F2F;

				&:not(:first-child){
					text-align: center;
				}

				.content-center{
					padding-top: 20px;
					padding-bottom: 20px;

					p{
						
						font-size: 13px;
						color: #6D6D6D;
					}
				}

				.input-number{
					display: inline-block;
					margin-bottom: 20px;
					border: 1px solid #2F2F2F;

					a,
					.input-default{
						position: relative;
						display: inline-block;
						vertical-align: middle;
						width: 35px;
						height: 39px;
					}

					a{
						background: #2F2F2F;

						.fa{
							position: absolute;
							top: 50%;
							left: 50%;
							color: #FFF;

							transform: translateX(-50%) translateY(-50%);
						}
					}
					
					.input-default{
						input{
							border: none;
							background: transparent;

							position: absolute;
							width: calc(100% + 10px);
							height: 100%;

							top: 0;
							left: 0;
							text-align: center;

							&:focus{
								outline: none;
							}
						}
					}
				}
				
				.photo{
					position: relative;
					min-width: 91px;
					padding-bottom: 95px;
					border: 1px solid #CCC;

					&:hover{
						.close-pedido{
							opacity: 1;
						}
					}

					.close-pedido{
						display: flex;
						justify-content: center;
						align-items: center;
						width: 25px;
						height: 25px;
						border-radius: 50%;
						background: rgba(#9D090D, .5);

						position: absolute;
						left: calc(100% - 12.5px);
						bottom: calc(100% - 12.5px);

						z-index: 119;

						opacity: 0;

						i{
							color: #FFF;
							font-size: 11px;
						}
					}

					.picture{
						position: absolute;
						width: 100%;
						height: 100%;

						display: flex;
						justify-content: center;
						align-items: center;
					}
				}


				.photo,
				.dados{
					display: inline-block;
					vertical-align: middle;
				}

				.dados{
					width: 80%;
					padding-left: 20px;
					
					font-weight: bold;

					.nome{
						font-size: 20px;
						color: #2F2F2F;
						margin-bottom: 12px;
					}

					.dist{
						font-size: 11px;
						color: #656565;

						span{
							color: $cor-default;
						}
					}
				}

				.preco-menor,
				.preco-maior,
				.preco-total{
					
					font-weight: bold;
					color: #333;

					&.preco-menor{
						font-size: 14px;
					}

					&:not(.preco-menor){
						font-size: 20px;
					}

					&.preco-maior{
						color: $cor-default;
					}
				}
			}
		}
	}
}

.cep-content{
	border: 1px solid #D2D2D2;

	.item-default{
		h3{
			margin-top: 15px;
			margin-bottom: 15px;
			
			font-size: 13px;
			font-weight: bold;
			color: #656565;

			padding-top: 12px;
			padding-bottom: 7px;
			padding-left: 54px;
			border-left: 3px solid $cor-default;

			span{
				color: #2F2F2F;
			}

			strong{
				color: #272727;
				text-transform: uppercase;
			}
		}

		.form-default{
			padding-left: 15px;
			@media (min-width: $screen-sm-min){
				padding-left: 49px;
			}

			input{
				display: block;
				width: 100%;
				@media (min-width: $screen-sm-min){
					max-width: 391px;
				}
				height: 50px;
				border: 1px solid #E3E3E3;
				border-radius: 6px;
				padding-left: 25px;
				padding-right: 25px;
				margin-bottom: 10px;

				
				font-size: 13px;
				color: #656565;

				@include placeholdIt(){
					
					font-size: 13px;
					color: #CCC;

				}

				&:focus{
					outline: none;
				}
			}

			.btn-cep{
				
				font-weight: bold;
				font-size: 11px;
				color: #2F2F2F;
			}

			.input-group{
				text-align: right;
			}

			.input-group,
			a{
				display: inline-block;
				vertical-align: top;
			}

			p{
				
				font-size: 12px;
				color: #252525;
				font-weight: bold;
			}
		}
	}
}

.total-itens-pedido,
.total-pedido{
	padding-top: 18px;
	padding-left: 28px;
	padding-bottom: 18px;

	&.total-itens-pedido{
		background: #2F2F2F;
		margin-bottom: 10px;
	}

	&.total-pedido{
		background: #E9E9E9;
	}

	.line-item{
		margin-top: 7px;
		.desc,
		.valor{
			display: inline-block;
			vertical-align: middle;

			
			font-size: 13px;
			font-weight: bold;
			color: #FFF;
		}

		.desc{
			width: 70%;
		}
	}
}

.total-pedido{
	.line-item{
		.desc,
		.valor{
			color: $cor-default;
			
			&.desc{
				width: 60%;
			}
			&.valor{
				font-size: 20px;
			}
		}
	}
}

.finalizar-compra{
	.cupom-default,
	a{
		display: inline-block;
	}

	.cupom-default{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		p{
			position: relative;
			width: 100%;
			@media (min-width: $screen-sm-min){
				max-width: 340px;
			}
			padding-left: 35px;

			
			font-size: 12px;
			color: #333;

			line-height: 110%;
			margin-bottom: 0;

			.icon{
				position: absolute;
				top: 50%;
				left: 0;

				transform: translateY(-50%);
			}
		}
		
		.input-group{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			input,
			a{
				display: inline-block;
				vertical-align: top;
			}

			input{
				margin-right: 15px;
				width: 100%;
				@media (min-width: $screen-sm-min){
					max-width: 195px;
				}
				height: 50px;
				border: 1px solid #E3E3E3;
				border-radius: 6px;
				padding-left: 25px;
				padding-right: 25px;

				
				font-size: 13px;
				color: #656565;

				@include placeholdIt(){
					
					font-size: 13px;
					color: #CCC;

				}

				&:focus{
					outline: none;
				}
			}
		}
	}
}